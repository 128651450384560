/* You can add global styles to this file, and also import other style files */

@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.min.css";

/* Layout */
@import "assets/variables";
@import "assets/layout/layout.scss";

@import "assets/override.scss";

:root {
  --font-family: 'Roboto', sans-serif;
}

body {
  background-color: var(--surface-a);
  font-family: var(--font-family);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .p-component {
    font-family: var(--font-family);
  }
}

.d-grid {
  display: grid;
}

.h-fit-content {
  height: fit-content;
}

.justify-items-center {
  justify-items: center;
}

.mb-4125 {
  margin-bottom: 4.125rem !important;
}

@mixin use-md-icon($icon) {
  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }

  &::before {
    content: $icon;
  }
}
