.layout-content-wrapper {
    position: relative;
    transition: margin-left var(--transition-duration);
    border-top-left-radius: 15px;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      border-top-left-radius: 15px;
      box-shadow: inset 0px 3px 4px rgb(0 0 0 / 10%);
    }

    .layout-content {
        overflow: auto;
        height: calc(100vh - 5rem);
        padding: 2rem;
        background-color: var(--surface-ground);

        .layout-content-inner {
            display: flex;
            position: relative;
            flex-direction: column;
            height: 100%;
        }
    }
}
