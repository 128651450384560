.layout-sidebar {
    width: 18rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    top: 5rem;
    user-select: none;
    background-color: var(--v-body-bg);
    z-index: 999;

    .layout-menu-container {
        overflow: auto;
        flex: 1;
        height: 100%;
        padding: 1rem 1.5rem;
    }

    .layout-menu-wrapper {
      display: flex;
      width: 100%;
      padding: 0 10px;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 1rem;
      background-color: #fff;
    }

    .layout-menu {
        margin: 0;
        padding: 0;
        list-style-type: none;

        .layout-root-menuitem {
            > .layout-menuitem-root-text {
                font-size: .857rem;
                text-transform: uppercase;
                font-weight: 700;
                padding: 1rem 0 .5rem 0;
                color: var(--v-menuitem-text-color-secondary);

                .active-route {
                    font-weight: 700;
                }
            }

            > a {
                display: none;
            }
        }

        li {
            &.active-menuitem {
                > a {
                    .layout-submenu-toggler {
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            a {
                display: flex;
                align-items: center;
                outline: 0 none;
                position: relative;
                color: var(--v-menuitem-text-color);
                padding: .5rem;
                margin-bottom: .25rem;
                border-radius: var(--border-radius);
                line-height: 1;
                transition: background-color var(--transition-duration), box-shadow var(--transition-duration);
                cursor: pointer;

                .layout-menuitem-icon {
                    margin-right: .5rem;
                }

                .layout-submenu-toggler {
                    font-size: 75%;
                    margin-left: auto;
                    transition: transform var(--transition-duration);
                }

                &.active-route {
                    font-weight: 700;
                }

                &:hover {
                    background: var(--v-menuitem-hover-bg);
                }
            }

            ul {
                overflow: hidden;
                border-radius: var(--border-radius);

                li {
                    a {
                        padding-left: 1.5rem;
                    }

                    li {
                        a {
                            padding-left: 2.5rem;
                        }

                        li {
                            a {
                                padding-left: 3.5rem;
                            }

                            li {
                                a {
                                    padding-left: 4.5rem;
                                }

                                li {
                                    a {
                                        padding-left: 5.5rem;
                                    }

                                    li {
                                        a {
                                            padding-left: 6.5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

