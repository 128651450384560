@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-MediumItalic.eot');
  src: url('../fonts/Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),
      url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Medium.eot');
  src: url('../fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Medium.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-LightItalic.eot');
  src: url('../fonts/Roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-LightItalic.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-LightItalic.woff') format('woff'),
      url('../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Bold.eot');
  src: url('../fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Bold.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-ThinItalic.eot');
  src: url('../fonts/Roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-ThinItalic.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-ThinItalic.woff') format('woff'),
      url('../fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BoldItalic.eot');
  src: url('../fonts/Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),
      url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Italic.eot');
  src: url('../fonts/Roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Italic.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.eot');
  src: url('../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Regular.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BlackItalic.eot');
  src: url('../fonts/Roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-BlackItalic.woff') format('woff'),
      url('../fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Thin.eot');
  src: url('../fonts/Roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Thin.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Thin.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Light.eot');
  src: url('../fonts/Roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Light.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Light.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Black.eot');
  src: url('../fonts/Roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Roboto/Roboto-Black.woff2') format('woff2'),
      url('../fonts/Roboto/Roboto-Black.woff') format('woff'),
      url('../fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}


h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1.5rem 0;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.2;
    color: inherit;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

mark {
    background: #FFF8E1;
    padding: .25rem .4rem;
    border-radius: var(--border-radius);
    font-family: monospace;
}

blockquote {
    margin: 1rem 0;
    padding: 0 2rem;
    border-left: 4px solid #90A4AE;
}

hr {
    border-top: solid var(--surface-border);
    border-width: 1px 0 0 0;
    margin: 1rem 0;
}

p {
    margin: 0 0 1rem 0;
    line-height: 1.5;

    &:last-child {
        margin-bottom: 0;
    }
}
