.layout-topbar {
    height: 5rem;
    padding: 0 2rem 0 1.1rem;
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    background-color: var(--v-body-bg);

    .app-logo {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        img {
            width: 13rem;
        }

        span {
            font-size: 2rem;
            font-weight: 700;
            margin-left: .5rem;
            color: var(--v-menuitem-text-color);
        }
    }

    .topbar-menubutton {
        position: relative;
        width: 3rem;
        height: 3rem;
        margin-left: 3rem;
        border-radius: var(--border-radius);
        flex-shrink: 0;

        span {
            width: 2rem;
            height: 4px;
            display: block;
            position: absolute;
            top: 18px;
            left: 7px;
            border-radius: 7px;
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            transition-timing-function: swing;
            background-color: var(--v-menuitem-text-color-secondary);

            &:before {
                width: 1rem;
                height: 4px;
                content: "";
                position: absolute;
                right: 0;
                top: -9px;
                border-radius: 7px;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                transition-timing-function: swing;
                background-color: var(--v-menuitem-text-color-secondary);
            }

            &:after {
                width: 1rem;
                height: 4px;
                content: "";
                position: absolute;
                left: 0;
                top: 9px;
                border-radius: 7px;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                transition-timing-function: swing;
                background-color: var(--v-menuitem-text-color-secondary);
            }
        }

        &:hover {
            background-color: var(--v-menuitem-hover-bg);
        }
    }
}

.menu-button {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background: rgba(33, 150, 243, 0.04);
  }

  &__bar {
    position: absolute;
    left: 50%;
    width: 18px;
    height: 1px;
    top: 50%;
    background-color: #2196F3;
    transition: 0.2s;

    &._bar-1 {
      transform: translate(-50%, calc(50% - 7px));
    }

    &._bar-2 {
      transform: translate(-50%, -50%);
    }

    &._bar-3 {
      transform: translate(-50%, calc(50% + 5px));
    }
  }

  &._open {
    .menu-button__bar {
      &._bar-1 {
        transform: translate(-50%, -50%) rotate(135deg);
      }

      &._bar-2 {
        transform: translate(-50%, -50%) rotate(-135deg);
      }

      &._bar-3 {
        transform: scale(0);
      }
    }
  }
}
