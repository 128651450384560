@media screen and (min-width: $breakpoint) {
    .layout-container {
        &.layout-slim {

            .layout-topbar {
                .app-logo {
                    span {
                        display: none;
                    }
                }
            }

            .topbar-menubutton {
                display: none;
            }

            .layout-sidebar {
                overflow: visible;
                width: 5rem;

                &._expanded {
                  width: 18rem;
                }

                &._collapsed {
                  width: 0;
                  overflow: hidden;
                }

                .layout-menu-container {
                    overflow: visible;
                    display: flex;
                    justify-content: center;
                    padding: 0;
                }

                .layout-menu-wrapper {
                  &._expanded {
                    .layout-root-menuitem {
                      > a {
                        .layout-menuitem-text {
                          display: block;
                          width: auto;
                          opacity: 1;
                          visibility: visible;
                        }
                      }
                    }
                  }
                }
            }

            .layout-menu {
                .layout-root-menuitem {
                    position: relative;

                    > .layout-menuitem-root-text {
                        display: none;
                    }

                    > a {
                        display: flex;
                        align-items: center;
                        border-radius: 6px;
                        cursor: pointer;
                        outline: none;
                        transition: background-color var(--transition-duration);
                        height: 3.5rem;
                        margin: 0 auto 1rem auto;
                        color: var(--v-menuitem-text-color);

                        .layout-submenu-toggler {
                            display: none;
                        }

                        .layout-menuitem-icon-wrapper {
                          display: inline-flex;
                          align-items: center;
                          justify-content: center;
                          width: 3.5rem;
                          height: 3.5rem;
                          border-radius: 25%;
                        }

                        .layout-menuitem-icon {
                            font-size: 1.75rem;

                            &._img {
                              width: 100%;
                            }
                        }


                        .layout-menuitem-text {
                          display: none;
                          font-weight: 600;
                          width: 0;
                          opacity: 0;
                          visibility: hidden;
                          margin-left: 0.5rem;
                        }

                        &:hover {
                          background: var(--v-menuitem-hover-bg);
                        }
                    }

                    > ul {
                        position: absolute;
                        left: 4rem;
                        top: 0;
                        min-width: 15rem;
                        background-color: var(--surface-overlay);
                        border-radius: var(--border-radius);
                        box-shadow: 0px 4px 10px rgb(0 0 0 / 3%), 0px 0px 2px rgb(0 0 0 / 6%), 0px 2px 6px rgb(0 0 0 / 12%);
                        padding: 1rem;
                        max-height: 20rem;
                        overflow: auto;
                        z-index: 999;

                        a {
                            color: var(--v-overlay-menuitem-text-color);

                            &:hover {
                                background: var(--v-overlay-menuitem-hover-bg);
                            }
                        }

                        li {
                            a {
                                padding-left: 0.5rem;
                            }

                            li {
                                a {
                                    padding-left: 1.5rem;
                                }

                                li {
                                    a {
                                        padding-left: 2.5rem;
                                    }

                                    li {
                                        a {
                                            padding-left: 3.5rem;
                                        }

                                        li {
                                            a {
                                                padding-left: 4.5rem;
                                            }

                                            li {
                                                a {
                                                    padding-left: 5.5rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
