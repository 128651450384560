.p-dialog-top {
    .p-dialog {
      margin-top: 10vh !important;
  }
}

.p-tooltip {
  &.process-actions {

    .p-tooltip-arrow {
      border-right-color: #fff;
    }

    .p-tooltip-text {
      background-color: #fff;
    }
  }
}

.process-list {
  .p-orderlist-controls {
    display: none;
  }

  .p-orderlist-list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    border: unset;
    padding: 0.5rem;
    border-radius: 0;
  }
}

.p-orderlist-item {
  border-radius: 6px;
  padding: 0;
  overflow: visible;
}
